module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pixel Friendly","short_name":"PixelFriendly","start_url":"/","background_color":"#278ADB","theme_color":"#278ADB","display":"minimal-ui","icon":"src/images/pf-logo.png","include_favicon":true,"icons":[{"src":"fav-icon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"fav-icon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"fav-icon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"fav-icon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"fav-icon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"fav-icon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"fav-icon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"fav-icon/icon-512x512.png","sizes":"512x512","type":"image/png"}],"cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"cacheDigest":"6feca7422bbd92082b3be50f17e6a22c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-86183749-1","head":false,"anonymize":true,"respectDNT":true,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"https://pixelfriendly.co.za/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
